<template>
  <el-dialog
    class="m-safety-dialog"
    :visible.sync="showPcSafety"
    width="88%">
    <div class="m-safety-dialog-content">
      <img class="safety-close" @click="showPcSafety = false" src="../assets/v3/team/close-dialog.png" alt="close">
      <div class="icon-box">
        <img class="m-safety-icon" src="../assets/v3/common/safe-tip-img.png" alt="safety">
      </div>
      <div class="m-safety-tip">
        Bantusaku tidak pernah meminta sejumlah uang atau deposit kepada calon meminjam sebagai syarat untuk memproses pengajuan pinjaman
      </div>
      <div class="m-safety-text">Jika anda membutuhkan bantuan, hubungi kami di :</div>
      <div class="m-safety-content">
        <div class="safety-item">
          <img src="../assets/v3/common/tel.png" alt="tel">
          <div>Telepon: 1500-006</div>
        </div>
        <div class="safety-item">
          <img src="../assets/v3/common/watsapp.png" alt="watsapp">
          <div>Whatsapp: 0889-1500-006</div>
        </div>
        <div class="safety-item">
          <img src="../assets/v3/common/sms.png" alt="sms">
          <div>Email: cs@bantusaku.id</div>
        </div>
      </div>
      <div class="m-safety-bottom-tip">
        <img src="../assets/v3/home/risk-tip.png" class="m-risk-img">
        <div class="start-tip">HATI-HATI, TRANSAKSI INI BERESIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN UANG.</div>
        <div>JANGAN BERUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR. PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI.</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPcSafety: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.m-safety-dialog-content{
  background: url("../assets/v3/home/m-safe.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 12px;
  position: relative;
  padding: 52px 25px 34px 25px;
  box-sizing: border-box;
  font-weight: 600;
  .safety-close{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: -36px;
    cursor: pointer;
  }
  .icon-box{
    .m-safety-icon{
      width: 60px;
      height: 70px;
      margin: 0 auto;
      position: absolute;
      top: -30px;
      left: 50%;
      margin-left: -35px;
    }
  }
  .m-safety-tip{
    font-size: 13px;
    color: #1D1D1D;
    line-height: 20px;
    font-weight: 600;
  }
  .m-safety-text{
    font-size: 13px;
    margin: 12px 0 10px 0;
    color: #1D1D1D;
    font-weight: 400;
  }
  .m-safety-content{
    .safety-item{
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: #145246;
      img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .safety-item:nth-child(2){
      margin: 15px 0;
    }
    
  }
  .m-safety-bottom-tip{
    width: 100%;
    background: url("../assets/v3/home/m-risk-banner.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
    padding: 12px 23px 14px 23px;
    box-sizing: border-box;
    font-size: 10px;
    color: #1D1D1D;
    display: flex;
    flex-direction: column;
    align-items: center;
    .m-risk-img{
      width: 34px;
      height: 28px;
      margin-bottom: 10px;
    }
    .start-tip{
      margin-bottom: 3px;
    }
  }
}
</style>