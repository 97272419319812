<template>
  <el-dialog
    class="feedback-dialog"
    :visible.sync="showPcFeedback">
    <div class="feedback-dialog-content">
      <img class="feedback-close" @click="showPcFeedback = false" src="../assets/v3/common/pc-close.png" alt="close">
        <div class="feedback-dialog-title">Umpan balik</div>
        <el-form label-position="left" :model="feedbackForm" :rules="rules" ref="feedbackForm" label-width="200px" class="fedback-form">
          <el-form-item label="Nama" prop="name">
          <el-input v-model="feedbackForm.name"></el-input>
        </el-form-item>
        <el-form-item label="Nomor telepon" prop="phone">
          <el-input v-model="feedbackForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="Konten umpan balik" prop="content">
          <el-input
            type="textarea"
            placeholder=""
            v-model="feedbackForm.content"
            maxlength="160"
            :rows="5"
            show-word-limit
          />
        </el-form-item>
        <el-form-item>
          <el-button class="send-btn" type="success" :loading="isLoadingBtn" @click="submitForm('feedbackForm')">Mengirim</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPcFeedback: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  data(){
    return{
      feedbackForm: {
        name:'',
        phone: '',
        content: ''
      },
      rules: {
        name: [
          { required: true, message: 'Harap diisi', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: 'Harap diisi', trigger: 'blur' },
        ],
        content: [
          { required: true, message: 'Harap diisi', trigger: 'blur' },
        ]
      },
      isLoadingBtn: false
    }
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoadingBtn = true
          setTimeout(() => {
            this.$message({
              message: 'Keberhasilan umpan balik',
              type: 'success',
              offset: 100
            })
            this.isLoadingBtn = false
            this.$refs.feedbackForm.resetFields()
            this.showPcFeedback = false
          },1000)
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback-dialog-content{
  min-height: 466px;
  background: #C6E8ED;
  border-radius: 14px;
  position: relative;
  padding: 45px 50px 30px 83px;
  box-sizing: border-box;
  font-weight: 600;
  border-radius: 8px !important;
  .feedback-dialog-title{
    font-size: 30px;
    color: #145246;
    margin-bottom: 40px;
  }
  .feedback-close{
    width: 26px;
    height: 26px;
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
  .send-btn{
    width: 364px ;
    background: #145246;
    height: 60px;
    border-radius: 30px;
    margin-left: 28px;
  }
}
</style>