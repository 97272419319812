<template>
  <el-dialog
    class="down-app-dialog"
    :visible.sync="showDownApp"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="88%">
    <div class="down-app-dialog-content">
      <img class="safety-close" @click="closeDownDialog" src="../assets/v3/team/close-dialog.png" alt="close">
      <div class="down-app-wrap">
        <div class="logo-wrap">
          <img src="../assets/v3/common/logo.png">
          <div>BantuSaku</div>
        </div>
        <div class="tip-wrap">Unduh Aplikasi untuk mendapatkan layanan yang lebih baik</div>
        <div class="down-btns">
          <img src="../assets/v3/common/google.png" class="google" v-if="device === 'android'" @click="downApp">
          <img src="../assets/v3/common/ios.png" class="ios" v-if="device === 'ios'" @click="downApp">
          <img src="../assets/v3/common/down.png" class="down" @click="downApp">
      </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { deviceType } from '@/utils/auth'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showDownApp: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  data () {
    return {
      device: ''
    }
  },
  mounted () {
    this.device = deviceType()
  },
  methods:{
    downApp(){
      console.log(this.device)
      if(this.device == 'ios'){
        window.open('https://apps.apple.com/id/app/bantusaku-pinjaman-online-ojk/id1619483828')
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.smartec.ft')
      }
    },
    closeDownDialog(){
      this.$emit('closeDownDialog')
    },
  }
}
</script>

<style lang="scss" scoped>
.down-app-dialog-content{
  background: #fff;
  border-radius: 12px;
  position: relative;
  padding: 52px 25px 34px 25px;
  box-sizing: border-box;
  font-weight: 600;
  .safety-close{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: -36px;
    cursor: pointer;
  }
  .down-app-wrap{
    .logo-wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 40px;
        height: 40px;
        margin-right: 9px;
      }
      div{
        color: #145246;
        font-size: 18px;
      }
    }
    .tip-wrap{
      font-size: 14px;
      font-weight: 600;
      margin: 20px 0;
      color: #000;
    }
    .down-btns{
      display: flex;
      align-items: center;
      justify-content: space-around;
      .ios, .google{
        width: 154px;
        cursor: pointer;
      }
      .down{
        width: 78px;
        cursor: pointer;
      }
    }
  }
}
</style>