<template>
  <el-dialog
    class="safety-dialog"
    :visible.sync="showPcSafety"
    width="993px">
    <div class="safety-dialog-content">
      <img class="safety-close" @click="showPcSafety = false" src="../assets/v3/common/pc-close.png" alt="close">
      <div class="safety-content">
        <div class="safety-content-left">
          <img src="../assets/v3/common/bantusaku.png">
          <div>Bantusaku tidak pernah meminta sejumlah uang atau deposit kepada calon meminjam sebagai syarat untuk memproses pengajuan pinjaman</div>          
        </div>
        <div class="safety-content-center">
          <img src="../assets/v3/common/safe-tip-img.png">
        </div>
        <div class="safety-content-right">
          <div class="right-tip">Jika anda membutuhkan bantuan, hubungi kami di :</div>
          <div class="right-content">
            <img src="../assets/v3/common/tel.png">
            <div>Telepon: 1500-006</div>
          </div>
          <div class="right-content">
            <img src="../assets/v3/common/watsapp.png">
            <div>Whatsapp: 0889-1500-006</div>
          </div>
          <div class="right-content">
            <img src="../assets/v3/common/sms.png">
            <div>Email: cs@bantusaku.id</div>
          </div>
      </div>
      </div>
      <div class="safety-bottom-tip">
        <img src="../assets/v3/home/risk-tip.png" class="risk-img">
        <div>
          <div class="start-tip">HATI-HATI, TRANSAKSI INI BERESIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN UANG.</div>
          <div>JANGAN BERUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR. PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI.</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPcSafety: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.safety-dialog-content{
  width: 993px !important;
  min-height: 210px;
  background: url("../assets/v3/home/pc-safe.png");
  background-size: 100% 100%;
  border-radius: 14px;
  position: relative;
  padding: 23px 80px 30px 50px;
  box-sizing: border-box;
  font-weight: 600;
  .safety-close{
    width: 26px;
    height: 26px;
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
  .safety-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .safety-content-left{
    margin-top: 20px;
      img{
        width: 206px;
      }
      div{
        width: 343px;
        color: #1D1D1D;
        font-size: 14px;
        text-align: left;
        margin-top: 10px;
      }
    }
    .safety-content-center{
      img{
        width: 76px;
        margin-top: -80px;
      }
    }
    .safety-content-right{
      padding-top: 18px;
      .right-tip{
        color: #1D1D1D;
        font-size: 14px;
        margin-bottom: 18px;
      }
      .right-content{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img{
          width: 20px;
          margin-right: 7px;
        }
        div{
          color: #145246;
          font-size: 16px;
        }
    }
  }
}
.safety-bottom-tip{
    width: 796px;
    background: url("../assets/v3/home/risk-banner.png") no-repeat;
    background-size: 100% 100%;
    margin: 20px auto 0 auto;
    padding: 20px 20px 20px 18px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #1D1D1D;
    .risk-img{
      width: 29px;
      height: 24px;
      margin-right: 5px;
    }
    .start-tip{
      margin-bottom: 4px;
    }
  }
}
</style>